.filterDropDown {
    max-height: 200px;
    overflow-y: auto;
    top: 100% !important;
    width: '-webkit-fill-available';
}
.filter .dropdown-toggle.btn-accent {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}