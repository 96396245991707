.job-card {
    padding: 8px !important;
    border-radius: 2px !important;
    color: #636c72 !important;
    display: block !important;
    min-height: 114px !important;
    &__btn-wrap {
      position: absolute;
      right: 8px;
      bottom: 8px;
    }
    &__btn-wrap-allocated-time {
      position: absolute;
      right: 8px;
      bottom: 8px;
    }
    &__btn-wrap-job-history {
      position: absolute;
      right: 8px;
      bottom: 8px;
    }
    &__btn-task-allocated-wrap {
      color: #636c72 !important;
    }
    &__link-color {
      color: #636c72 !important;
    }
    &__employees {
      @extend .job-card;
      border-bottom: 5px solid #46B147 !important;
    }
    &__geo-fence {
      @extend .job-card;
      border-bottom: 5px solid #46B147 !important;
      &:focus{
        outline:none;
      }
    }
    
    &__pay-period {
      @extend .job-card;
      border-bottom: 5px solid #46B147 !important;
      &:focus{
        outline:none;
      }
    }
    &__task {
      @extend .job-card;
      border-bottom: 5px solid #46B147 !important;
    }
    &__history {
      @extend .job-card;
      border-bottom: 5px solid #46B147 !important;
    }
    h5 {
      display: inline;
      color: #162d6e !important;
      margin-bottom: 0px;
      font-size: 18px;
    }
    small {
      font-size: 14px;
      padding-left: 6px;
      font-weight: 500;
    }
  }
  
  .job-history {
    font-size: 12px !important;
  }
  .blue-background{
    background-color: #F0F9FE;
  }
  

  .progressBar{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    color:  #46B147;
    border: 1px solid #70707040;
    box-shadow: 0px 3px 6px #00000033;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;

    font-size: 15px;
    width: 25%
  
  }
  
  .progressBarborders{
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    background-color: white;
    border:  1px solid #70707040;
    box-shadow: 0px 3px 6px #00000033;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 25%
  }
  
  .progressBarEnd{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    color: #46B147;
    font-size: 15px;
    border: 1px solid #70707040;
    box-shadow: 0px 3px 6px #00000033;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width:25%;
  }
  
  @media screen and (max-width: 1283px){
    .progressBar{
      font-size: 14px !important;
    }
    .progressBarborders{
      font-size: 14px !important;
    }
    .progressBarEnd{
      font-size: 14px !important;
    }
  }

  .ReactTable {
    & .employee_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center !important;
    }
    & .rt-tr {
      &:hover {
        background: #9CDAD7 !important;
      }
    }
    & .rt-td {
      min-height: 33px;
      max-height: 60px
    }
  }


  .ReactTable {
    & .employee_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center !important;
    }
    & .rt-tr {
      &:hover {
        background: #9CDAD7 !important;
      }
    }
  }



  .ReactTable {
    & .rt-tbody {
      & .rt-tr-group {
        border-bottom: none !important;
      }
    }
    & .rt-thead {
      height: 39px;
      box-shadow: none !important;
      & .rt-tr {
        padding-top: 0.3rem;
        background: #001E2E !important;
        color: #fff;
        font-size: 12px !important;
        font-weight: 700;
        text-align: left;
        padding-top: 0.2rem;
        & .rt-th {
          border: none !important;
          padding-top: 0.6rem !important;
          //border-right: 1px solid #F2F2F2 !important;
        }
      }
    }
  
    & .rt-tbody .rt-td {
      color: #242424;
      display: flex;
  
      b {
        color: #0F2BB4 !important;
        font-weight: 500;
      }
    }
  
    .rt-head .rt-tr .rt-th {
      font-size: 12px !important;
      font-weight: 700 !important;
    }
  }
  

  .ReactTable .rt-resizer{
    width: 10px !important;
    right: -5px !important;
  }

  .ReactTable .rt-noData{
    top: 40% !important;
    height: 20px;
    padding: 5px !important;
    background: transparent;
    color: #001E2E;
    font-size: 14px;
    font-weight: 400;
  }


  .ReactTable .rt-tr .employee-div .tooltip:hover {
    background: #9CDAD7 !important;
  }


  .ReactTable .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    
    padding: 5px;
    //box-shadow: 0 0 15px 0 rgb(0 0 0 / 10);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: fit-content;
  border: 0;
  border-radius: 3px;
  padding: 7px;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all .1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: small;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
  font-size: 14px;
}


//form constrol

.form-control {
  font-size: 13px !important;
  line-height: 1.2 !important;
}
.form-control[readonly] {
  background-color: #fff !important;
}
.form-control:disabled {
   background-color: #DDDDDD !important;
}
.form-control:focus {
  border-color: #162d6e !important;
}

.form-control-error {
  border-color: #d9534f !important;
}

.form-control-label {
  display: inline-block !important;
  margin-bottom: 0rem !important;
}

.job-report-filter .flatpickr-input , .job-report-filter .input-group-addon {
  height: 34px !important;
}